import { axios } from '@/utils/request'

const api = {
    add: 'charging/chargingpileRole/add', // 计时收费方案分页查询
    selectInfo: 'charging/chargingpileRole/selectInfo', // 角色菜单查询
    delete: 'charging/chargingpileRole/delete', // 删除角色
    update: 'charging/chargingpileRole/update', //修改角色

}
export default api

export const addRoleApi = (data) => {
    return axios({
        url: api.add,
        method: 'post',
        data
    })
}

export const selectInfoApi = (params) => {
    return axios({
        url: api.selectInfo,
        method: 'post',
        params
    })
}

export const deleteRoleApi = (data) => {
    return axios({
        url: api.delete,
        method: 'post',
        data
    })
}

export const updateRoleApi = (data) => {
    return axios({
        url: api.update,
        method: 'post',
        data
    })
}
