<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
                clearable
                placeholder="请输入角色"
                class="search-input"
                prefix-icon="search"
                size="small"
                @input="searchInput"
                v-model="roleInput"
            ></el-input>
<!--            <el-select-->
<!--                clearable-->
<!--                class="search-input"-->
<!--                placeholder="请选择角色"-->
<!--                size="small"-->
<!--                @change="searchInput"-->
<!--                v-model="deviceStatusInput">-->
<!--              <el-option-->
<!--                  v-for="item in deviceStatusOption"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--              >{{ item.label }}</el-option>-->
<!--            </el-select>-->
            <el-button type="primary" size="small" @click="searchInput">搜索</el-button>
            <el-button type="primary" size="small" @click="addClick">添加角色</el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
            ref="tableRef"
            :data="tableData"
            style="width: 100%"
            max-height="630px"
            :header-cell-style="{ background: '#F2F4F8' }"
            @selection-change="handleSelectionChange"
        >
          <el-table-column
              v-for="(item, index) in tableColumnData"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="230">
            <template #default="{ row }">
              <el-button type="text" @click="editClick(row)">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-popconfirm
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  @confirm="confirmDelete(row)"
                  :title="'确认是否删除' + '“' + row.roleName + '”' + '？'">
                <template #reference>
                  <el-button type="text">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
            :total="total"
            @pageChange="handleSizeChange"
            @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
    </el-card>
    <!-- 新建设备 -->
    <el-dialog
        v-model="visible"
        width="40%"
        :title="titleName"
        :close-on-click-modal="false"
        @closed="resetForm"
    >
      <el-form
          label-width="95px"
          :inline="true"
          :model="formData"
          :rules="addRules"
          ref="formRef">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="角色名称：" prop="roleName">
              <el-input
                  placeholder="请输入角色名称"
                  v-model="formData.roleName"
                  :disabled="editStatusDisable"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="角色备注：" prop="roleRemark">
              <el-input
                  placeholder="请输入角色备注"
                  v-model="formData.roleRemark"
                  maxlength="100"
                  show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="角色权限：" prop="menuIds">
              <div class="treeDom">
                <el-scrollbar style="height: 100%">
                  <el-tree
                      style="height: 100%; overflow: auto !important"
                      ref="menuTreeRef"
                      v-model="formData.menuIds"
                      show-checkbox
                      :data="treeData"
                      node-key="id"
                      default-expand-all
                      highlight-current
                      @check-change="handleMenuClick"
                      :expand-on-click-node="false"
                  />
                </el-scrollbar>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">取消 </el-button>
          <el-button type="primary" @click="submitForm('formRef')">保存</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import pageNation from "@/components/base/pageNation";
import {getCurrentInstance, onMounted, reactive, toRefs} from "vue-demi";
import encryptUtil from "@/utils/encryptUtil";
import {addRoleApi, deleteRoleApi, selectInfoApi, updateRoleApi} from "@/api/authorityManagement/roleManagement";
import {requestApi} from "@/utils/requestApi";
import {nextTick} from "vue";
import {debounces} from "@/common/utils";

export default {
  name: "RoleManagement",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      tableData: [], // 表格数据
      tableColumnData: [
        {
          prop: "roleName",
          label: "角色名称",
        },
        {
          prop: "roleRemark",
          label: "备注",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      titleName: "添加角色",
      visible: false,
      userInfo: null,
      formData: {
        roleName: "",
        roleRemark: "",
        menuIds: [],
      },
      addRules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 0, max: 10, message: '请输入0-10个字符', trigger: 'blur' },
        ],
        roleRemark: [
          { required: false, message: '请输入角色备注', trigger: 'blur' },
        ],
        menuIds: [
          { required: true, message: '请选择角色权限', trigger: 'blur' },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
        ],
      },
      roleInput: '',
      treeData: [],
    });

    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.selectInfo();
    }, 500);

    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.treeData = JSON.parse(localStorage.getItem("menus"));
      methods.selectInfo()
    });

    const methods = {
      // 查询角色
      selectInfo() {
        requestApi(() => {
          return selectInfoApi({
            current: data.currentPage,
            size: data.pageSize,
            roleName: data.roleInput
          })
        },(res) => {
          data.tableData = res.data.records
          proxy.total = res.data.total
          proxy.currentPage = res.data.current
          proxy.pageSize = res.data.size
        })
      },
      handleSizeChange(val) {
        proxy.pageSize = val
        methods.selectInfo()
      },
      handleCurrentChange(val) {
        proxy.currentPage = val
        methods.selectInfo()
      },
      // 点击新增
      addClick() {
        data.visible = true
        data.titleName = '添加角色'
      },
      // 点击编辑
      editClick(row) {
        // console.log(row,'row')
        data.visible = true
        data.titleName = '编辑角色'
        nextTick(() => {
          proxy.formData = JSON.parse(JSON.stringify(row));
          proxy.$refs.menuTreeRef.setCheckedKeys(proxy.formData.menuIds)
        })
      },
      submitForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (data.titleName === '添加角色') {
              await methods.addRole(data.formData)
            } else {
              await methods.updateRole(data.formData)
            }
            await methods.selectInfo()
            methods.resetForm()
          }
        })
      },
      // 新增接口
      async addRole(formData) {
        await requestApi(() => {
          return addRoleApi(formData)
        },(res) => {
          proxy.$notify({
            title: "成功",
            message: res.message,
            type: "success",
            duration: 1000,
          });
        })
      },
      async updateRole(formData) {
        await requestApi(() => {
          return updateRoleApi(formData)
        },(res) => {
          proxy.$notify({
            title: "成功",
            message: res.message,
            type: "success",
            duration: 1000,
          });
        })
      },
      // 当复选框被点击的时候触发
      handleMenuClick(a,b,c) {
        // a:传递给data属性的数组中该节点所对应的对象、 b:节点本身是否被选中、 c:节点的子树中是否有被选中的节点
        // console.log(a,b,c)

        data.formData.menuIds = []
        const roleArr = proxy.$refs.menuTreeRef.getCheckedKeys();
        const roleArrs = proxy.$refs.menuTreeRef.getHalfCheckedKeys();
        proxy.formData.menuIds.push(...roleArr, ...roleArrs);
        console.log(data.formData.menuIds,'menuIds')
      },

      // tree 在勾选子节点时，自动勾选父节点
      getCurrentNode(data) {
        const node = proxy.$refs.menuTreeRef.getNode(data)
        // methods.childNodes(node)
        methods.parentNodes(node)
      },
      parentNodes (node) {
        if (node.parent) {
          for (const key in node) {
            if (key === 'parent') {
              node[key].checked = true
              methods.parentNodes(node[key])
            }
          }
        }
      },
      childNodes(node){
        const len = node.childNodes.length
        for (let i = 0; i < len; i += 1) {
          node.childNodes[i].checked = node.checked
          methods.childNodes(node.childNodes[i])
        }
      },
      // 关闭添加对话框
      resetForm() {
        data.visible = false
        proxy.$refs.formRef.resetFields()
        // 清空菜单树
        proxy.$refs.menuTreeRef.setCheckedKeys([])
      },
      // 删除
      confirmDelete(row) {
        requestApi(() => {
          return deleteRoleApi({roleId: row.roleId})
        },(res) => {
          methods.selectInfo()
          proxy.$notify({
            title: "成功",
            message: res.message,
            type: "success",
            duration: 1000,
          });
        })
      }
    }

    return {
      ...toRefs(data),
      ...methods,
      searchInput
    }
  },
}
</script>

<style lang="scss" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
  .formClass {
    display: inline-block;
    margin-right: 4px;
  }
}
.page-container {
  width: 100%;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}
.search-input-class {
  width: 100%;
}
.treeDom {
  margin-top: 20px;
  border: 1px solid var(--el-card-border-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  height: 50vh;
  overflow: auto auto;
}

::v-deep.el-form-item__label {
  white-space: nowrap;
}
::v-deep .el-form-item {
  width: 100%;
}
</style>
